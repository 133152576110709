// ---------------------------------------------------------------------------------------------------------------------
// types
// ---------------------------------------------------------------------------------------------------------------------

export type Status = 'success' | 'info' | 'warning' | 'error'

/**
 * Tailwind icon class names
 */
export type IconClass =
  | string
  | 'i-heroicons-academic-cap'
  | 'i-heroicons-adjustments-horizontal'
  | 'i-heroicons-adjustments-vertical'
  | 'i-heroicons-archive-box-arrow-down'
  | 'i-heroicons-archive-box-x-mark'
  | 'i-heroicons-archive-box'
  | 'i-heroicons-arrow-down-circle'
  | 'i-heroicons-arrow-down-left'
  | 'i-heroicons-arrow-down-on-square-stack'
  | 'i-heroicons-arrow-down-on-square'
  | 'i-heroicons-arrow-down-right'
  | 'i-heroicons-arrow-down-tray'
  | 'i-heroicons-arrow-down'
  | 'i-heroicons-arrow-left-circle'
  | 'i-heroicons-arrow-left-end-on-rectangle'
  | 'i-heroicons-arrow-left-start-on-rectangle'
  | 'i-heroicons-arrow-left'
  | 'i-heroicons-arrow-long-down'
  | 'i-heroicons-arrow-long-left'
  | 'i-heroicons-arrow-long-right'
  | 'i-heroicons-arrow-long-up'
  | 'i-heroicons-arrow-path-rounded-square'
  | 'i-heroicons-arrow-path'
  | 'i-heroicons-arrow-right-circle'
  | 'i-heroicons-arrow-right-end-on-rectangle'
  | 'i-heroicons-arrow-right-start-on-rectangle'
  | 'i-heroicons-arrow-right'
  | 'i-heroicons-arrow-top-right-on-square'
  | 'i-heroicons-arrow-trending-down'
  | 'i-heroicons-arrow-trending-up'
  | 'i-heroicons-arrow-turn-down-left'
  | 'i-heroicons-arrow-turn-down-right'
  | 'i-heroicons-arrow-turn-left-down'
  | 'i-heroicons-arrow-turn-left-up'
  | 'i-heroicons-arrow-turn-right-down'
  | 'i-heroicons-arrow-turn-right-up'
  | 'i-heroicons-arrow-turn-up-left'
  | 'i-heroicons-arrow-turn-up-right'
  | 'i-heroicons-arrow-up-circle'
  | 'i-heroicons-arrow-up-left'
  | 'i-heroicons-arrow-up-on-square-stack'
  | 'i-heroicons-arrow-up-on-square'
  | 'i-heroicons-arrow-up-right'
  | 'i-heroicons-arrow-up-tray'
  | 'i-heroicons-arrow-up'
  | 'i-heroicons-arrow-uturn-down'
  | 'i-heroicons-arrow-uturn-left'
  | 'i-heroicons-arrow-uturn-right'
  | 'i-heroicons-arrow-uturn-up'
  | 'i-heroicons-arrows-pointing-in'
  | 'i-heroicons-arrows-pointing-out'
  | 'i-heroicons-arrows-right-left'
  | 'i-heroicons-arrows-up-down'
  | 'i-heroicons-at-symbol'
  | 'i-heroicons-backspace'
  | 'i-heroicons-backward'
  | 'i-heroicons-banknotes'
  | 'i-heroicons-bars-2'
  | 'i-heroicons-bars-3-bottom-left'
  | 'i-heroicons-bars-3-bottom-right'
  | 'i-heroicons-bars-3-center-left'
  | 'i-heroicons-bars-3'
  | 'i-heroicons-bars-4'
  | 'i-heroicons-bars-arrow-down'
  | 'i-heroicons-bars-arrow-up'
  | 'i-heroicons-battery-0'
  | 'i-heroicons-battery-100'
  | 'i-heroicons-battery-50'
  | 'i-heroicons-beaker'
  | 'i-heroicons-bell-alert'
  | 'i-heroicons-bell-slash'
  | 'i-heroicons-bell-snooze'
  | 'i-heroicons-bell'
  | 'i-heroicons-bold'
  | 'i-heroicons-bolt-slash'
  | 'i-heroicons-bolt'
  | 'i-heroicons-book-open'
  | 'i-heroicons-bookmark-slash'
  | 'i-heroicons-bookmark-square'
  | 'i-heroicons-bookmark'
  | 'i-heroicons-briefcase'
  | 'i-heroicons-bug-ant'
  | 'i-heroicons-building-library'
  | 'i-heroicons-building-office-2'
  | 'i-heroicons-building-office'
  | 'i-heroicons-building-storefront'
  | 'i-heroicons-cake'
  | 'i-heroicons-calculator'
  | 'i-heroicons-calendar-date-range'
  | 'i-heroicons-calendar-days'
  | 'i-heroicons-calendar'
  | 'i-heroicons-camera'
  | 'i-heroicons-chart-bar-square'
  | 'i-heroicons-chart-bar'
  | 'i-heroicons-chart-pie'
  | 'i-heroicons-chat-bubble-bottom-center-text'
  | 'i-heroicons-chat-bubble-bottom-center'
  | 'i-heroicons-chat-bubble-left-ellipsis'
  | 'i-heroicons-chat-bubble-left-right'
  | 'i-heroicons-chat-bubble-left'
  | 'i-heroicons-chat-bubble-oval-left-ellipsis'
  | 'i-heroicons-chat-bubble-oval-left'
  | 'i-heroicons-check-badge'
  | 'i-heroicons-check-circle'
  | 'i-heroicons-check'
  | 'i-heroicons-chevron-double-down'
  | 'i-heroicons-chevron-double-left'
  | 'i-heroicons-chevron-double-right'
  | 'i-heroicons-chevron-double-up'
  | 'i-heroicons-chevron-down'
  | 'i-heroicons-chevron-left'
  | 'i-heroicons-chevron-right'
  | 'i-heroicons-chevron-up-down'
  | 'i-heroicons-chevron-up'
  | 'i-heroicons-circle-stack'
  | 'i-heroicons-clipboard-document-check'
  | 'i-heroicons-clipboard-document-list'
  | 'i-heroicons-clipboard-document'
  | 'i-heroicons-clipboard'
  | 'i-heroicons-clock'
  | 'i-heroicons-cloud-arrow-down'
  | 'i-heroicons-cloud-arrow-up'
  | 'i-heroicons-cloud'
  | 'i-heroicons-code-bracket-square'
  | 'i-heroicons-code-bracket'
  | 'i-heroicons-cog-6-tooth'
  | 'i-heroicons-cog-8-tooth'
  | 'i-heroicons-cog'
  | 'i-heroicons-command-line'
  | 'i-heroicons-computer-desktop'
  | 'i-heroicons-cpu-chip'
  | 'i-heroicons-credit-card'
  | 'i-heroicons-cube-transparent'
  | 'i-heroicons-cube'
  | 'i-heroicons-currency-bangladeshi'
  | 'i-heroicons-currency-dollar'
  | 'i-heroicons-currency-euro'
  | 'i-heroicons-currency-pound'
  | 'i-heroicons-currency-rupee'
  | 'i-heroicons-currency-yen'
  | 'i-heroicons-cursor-arrow-rays'
  | 'i-heroicons-cursor-arrow-ripple'
  | 'i-heroicons-device-phone-mobile'
  | 'i-heroicons-device-tablet'
  | 'i-heroicons-divide'
  | 'i-heroicons-document-arrow-down'
  | 'i-heroicons-document-arrow-up'
  | 'i-heroicons-document-chart-bar'
  | 'i-heroicons-document-check'
  | 'i-heroicons-document-currency-bangladeshi'
  | 'i-heroicons-document-currency-dollar'
  | 'i-heroicons-document-currency-euro'
  | 'i-heroicons-document-currency-pound'
  | 'i-heroicons-document-currency-rupee'
  | 'i-heroicons-document-currency-yen'
  | 'i-heroicons-document-duplicate'
  | 'i-heroicons-document-magnifying-glass'
  | 'i-heroicons-document-minus'
  | 'i-heroicons-document-plus'
  | 'i-heroicons-document-text'
  | 'i-heroicons-document'
  | 'i-heroicons-ellipsis-horizontal-circle'
  | 'i-heroicons-ellipsis-horizontal'
  | 'i-heroicons-ellipsis-vertical'
  | 'i-heroicons-envelope-open'
  | 'i-heroicons-envelope'
  | 'i-heroicons-equals'
  | 'i-heroicons-exclamation-circle'
  | 'i-heroicons-exclamation-triangle'
  | 'i-heroicons-eye-dropper'
  | 'i-heroicons-eye-slash'
  | 'i-heroicons-eye'
  | 'i-heroicons-face-frown'
  | 'i-heroicons-face-smile'
  | 'i-heroicons-film'
  | 'i-heroicons-finger-print'
  | 'i-heroicons-fire'
  | 'i-heroicons-flag'
  | 'i-heroicons-folder-arrow-down'
  | 'i-heroicons-folder-minus'
  | 'i-heroicons-folder-open'
  | 'i-heroicons-folder-plus'
  | 'i-heroicons-folder'
  | 'i-heroicons-forward'
  | 'i-heroicons-funnel'
  | 'i-heroicons-gif'
  | 'i-heroicons-gift-top'
  | 'i-heroicons-gift'
  | 'i-heroicons-globe-alt'
  | 'i-heroicons-globe-americas'
  | 'i-heroicons-globe-asia-australia'
  | 'i-heroicons-globe-europe-africa'
  | 'i-heroicons-h1'
  | 'i-heroicons-h2'
  | 'i-heroicons-h3'
  | 'i-heroicons-hand-raised'
  | 'i-heroicons-hand-thumb-down'
  | 'i-heroicons-hand-thumb-up'
  | 'i-heroicons-hashtag'
  | 'i-heroicons-heart'
  | 'i-heroicons-home-modern'
  | 'i-heroicons-home'
  | 'i-heroicons-identification'
  | 'i-heroicons-inbox-arrow-down'
  | 'i-heroicons-inbox-stack'
  | 'i-heroicons-inbox'
  | 'i-heroicons-information-circle'
  | 'i-heroicons-italic'
  | 'i-heroicons-key'
  | 'i-heroicons-language'
  | 'i-heroicons-lifebuoy'
  | 'i-heroicons-light-bulb'
  | 'i-heroicons-link-slash'
  | 'i-heroicons-link'
  | 'i-heroicons-list-bullet'
  | 'i-heroicons-lock-closed'
  | 'i-heroicons-lock-open'
  | 'i-heroicons-magnifying-glass-circle'
  | 'i-heroicons-magnifying-glass-minus'
  | 'i-heroicons-magnifying-glass-plus'
  | 'i-heroicons-magnifying-glass'
  | 'i-heroicons-map-pin'
  | 'i-heroicons-map'
  | 'i-heroicons-megaphone'
  | 'i-heroicons-microphone'
  | 'i-heroicons-minus-circle'
  | 'i-heroicons-minus'
  | 'i-heroicons-moon'
  | 'i-heroicons-musical-note'
  | 'i-heroicons-newspaper'
  | 'i-heroicons-no-symbol'
  | 'i-heroicons-numbered-list'
  | 'i-heroicons-paint-brush'
  | 'i-heroicons-paper-airplane'
  | 'i-heroicons-paper-clip'
  | 'i-heroicons-pause-circle'
  | 'i-heroicons-pause'
  | 'i-heroicons-pencil-square'
  | 'i-heroicons-pencil'
  | 'i-heroicons-percent-badge'
  | 'i-heroicons-phone-arrow-down-left'
  | 'i-heroicons-phone-arrow-up-right'
  | 'i-heroicons-phone-x-mark'
  | 'i-heroicons-phone'
  | 'i-heroicons-photo'
  | 'i-heroicons-play-circle'
  | 'i-heroicons-play-pause'
  | 'i-heroicons-play'
  | 'i-heroicons-plus-circle'
  | 'i-heroicons-plus'
  | 'i-heroicons-power'
  | 'i-heroicons-presentation-chart-bar'
  | 'i-heroicons-presentation-chart-line'
  | 'i-heroicons-printer'
  | 'i-heroicons-puzzle-piece'
  | 'i-heroicons-qr-code'
  | 'i-heroicons-question-mark-circle'
  | 'i-heroicons-queue-list'
  | 'i-heroicons-radio'
  | 'i-heroicons-receipt-percent'
  | 'i-heroicons-receipt-refund'
  | 'i-heroicons-rectangle-group'
  | 'i-heroicons-rectangle-stack'
  | 'i-heroicons-rocket-launch'
  | 'i-heroicons-rss'
  | 'i-heroicons-scale'
  | 'i-heroicons-scissors'
  | 'i-heroicons-server-stack'
  | 'i-heroicons-server'
  | 'i-heroicons-share'
  | 'i-heroicons-shield-check'
  | 'i-heroicons-shield-exclamation'
  | 'i-heroicons-shopping-bag'
  | 'i-heroicons-shopping-cart'
  | 'i-heroicons-signal-slash'
  | 'i-heroicons-signal'
  | 'i-heroicons-slash'
  | 'i-heroicons-sparkles'
  | 'i-heroicons-speaker-wave'
  | 'i-heroicons-speaker-x-mark'
  | 'i-heroicons-square-2-stack'
  | 'i-heroicons-square-3-stack-3d'
  | 'i-heroicons-squares-2x2'
  | 'i-heroicons-squares-plus'
  | 'i-heroicons-star'
  | 'i-heroicons-stop-circle'
  | 'i-heroicons-stop'
  | 'i-heroicons-strikethrough'
  | 'i-heroicons-sun'
  | 'i-heroicons-swatch'
  | 'i-heroicons-table-cells'
  | 'i-heroicons-tag'
  | 'i-heroicons-ticket'
  | 'i-heroicons-trash'
  | 'i-heroicons-trophy'
  | 'i-heroicons-truck'
  | 'i-heroicons-tv'
  | 'i-heroicons-underline'
  | 'i-heroicons-user-circle'
  | 'i-heroicons-user-group'
  | 'i-heroicons-user-minus'
  | 'i-heroicons-user-plus'
  | 'i-heroicons-user'
  | 'i-heroicons-users'
  | 'i-heroicons-variable'
  | 'i-heroicons-video-camera-slash'
  | 'i-heroicons-video-camera'
  | 'i-heroicons-view-columns'
  | 'i-heroicons-viewfinder-circle'
  | 'i-heroicons-wallet'
  | 'i-heroicons-wifi'
  | 'i-heroicons-window'
  | 'i-heroicons-wrench-screwdriver'
  | 'i-heroicons-wrench'
  | 'i-heroicons-x-circle'
  | 'i-heroicons-x-mark'

// ---------------------------------------------------------------------------------------------------------------------
// values
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Status-related classes
 */
export const statusClasses = {
  success: {
    border: 'border-green-600/30',
    icon: 'i-heroicons-check-circle',
    text: 'text-forgd-green-600',
    bg: 'bg-forgd-green-600/10',
  },
  info: {
    border: 'border-primary-300/30',
    icon: 'i-heroicons-information-circle',
    text: 'text-forgd-primary-300',
    bg: 'bg-neutral-600/30',
  },
  warning: {
    border: 'border-yellow-700/30',
    icon: 'i-heroicons-exclamation-triangle',
    text: 'text-forgd-yellow-700',
    bg: 'bg-forgd-yellow-700/10',
  },
  error: {
    border: 'border-red-600/30',
    icon: 'i-heroicons-exclamation-circle',
    text: 'text-forgd-red-600',
    bg: 'bg-forgd-red-600/10',
  },
  neutral: {
    border: 'border-slate-600/30',
    icon: 'i-heroicons-information-circle',
    text: 'text-slate-600',
    bg: 'bg-slate-600/10',
  },
}

